import React, {useEffect, useState} from 'react'
import {Link} from 'react-router-dom'
import {useLocation, useParams} from 'react-router'
import {KTSVG} from '../../../../_metronic/helpers'
import {
  getAccessDetails,
  getApplicationWithMetadata,
  getFTPDetails,
} from '../applications-list/core/_requests'
import {
  AccessDetailsDto,
  ApplicationMetadata,
  ApplicationType,
  FTPDetails,
} from '../applications-list/core/_models'

const ApplicationHeader: React.FC = () => {
  const location = useLocation()
  const {applicationId} = useParams()

  const [accessDetails, setAccessDetails] = useState<AccessDetailsDto>()
  const [ftpDetails, setFTPDetails] = useState<FTPDetails>()

  const [applicationMetadata, setApplicationMetadata] = useState<ApplicationMetadata>()

  useEffect(() => {
    getApplicationWithMetadata(applicationId ?? '').then((resp) => {
      setApplicationMetadata(resp)
    })

    getAccessDetails(applicationId ?? '').then((resp) => {
      setAccessDetails(resp)
    })

    getFTPDetails(applicationId ?? '').then((resp) => {
      setFTPDetails(resp)
    })
  }, [applicationId])

  return (
    <div className='card mb-2 mb-xl-4'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div className='symbol symbol-60px symbol-lg-80px symbol-fixed position-relative'>
              <img src={applicationMetadata?.logo} alt={applicationMetadata?.applicationName} />
            </div>
          </div>

          <div className='flex-grow-1'>
            <div className='d-flex fw-bold justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <p className='text-gray-800 fs-2 fw-bolder me-1'>
                    {applicationMetadata?.applicationName}
                  </p>
                </div>

                <div className='d-flex flex-wrap fs-6 mb-4 pe-2'>
                  {applicationMetadata?.projectName && (
                    <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                      <KTSVG
                        path='/media/icons/duotune/files/fil012.svg'
                        className='svg-icon-4 me-1'
                      />
                      {applicationMetadata?.projectName}
                    </p>
                  )}
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil020.svg'
                      className='svg-icon-4 me-2'
                    />
                    {applicationMetadata?.serverName}
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/general/gen001.svg'
                      className='svg-icon-4 me-2'
                    />
                    {applicationMetadata?.internalDomain}
                  </p>
                  <p className='d-flex fw-bold align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/coding/cod007.svg'
                      className='svg-icon-4 me-1'
                    />
                    <a
                      target='_blank'
                      href={
                        'https://' +
                        (applicationMetadata?.customDomain
                          ? applicationMetadata?.customDomain
                          : applicationMetadata?.internalDomain)
                      }
                    >
                      {applicationMetadata?.customDomain
                        ? applicationMetadata?.customDomain
                        : applicationMetadata?.internalDomain}
                    </a>
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/coding/cod011.svg'
                      className='svg-icon-4 me-2'
                    />
                    <a
                      target='_blank'
                      href={`https://database.cloudphant.com/?server=${ftpDetails?.ip}&username=${accessDetails?.accessDetails[0].details['MYSQL_USER'].value}&db=${accessDetails?.accessDetails[0].details['MYSQL_DATABASE'].value}`}
                    >
                      Access Database
                    </a>
                  </p>
                  <p className='d-flex align-items-center text-gray-400 me-5 mb-2'>
                    <KTSVG
                      path='/media/icons/duotune/files/fil018.svg'
                      className='svg-icon-4 me-1'
                    />
                    <a target='_blank' href='https://filemanager.cloudphant.com'>
                      File Manager
                    </a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className='d-flex fw-bold overflow-auto h-55px'>
          <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bolder flex-nowrap'>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/application/' + applicationId + '/access-details'
                    ? 'active'
                    : '')
                }
                to={`/application/${applicationId}/access-details`}
              >
                Access Details
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/application\/[a-fA-F0-9-]+\/domain-manager\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/application/${applicationId}/domain-manager`}
              >
                Domain Manager
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/application\/[a-fA-F0-9-]+\/monitoring-logs\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/application/${applicationId}/monitoring-logs`}
              >
                Monitoring & Logs
              </Link>
            </li>
            {applicationMetadata?.type === ApplicationType.LIVE && (
              <li className='nav-item'>
                <Link
                  className={
                    `nav-link text-active-primary me-6 ` +
                    (location.pathname === '/application/' + applicationId + '/staging'
                      ? 'active'
                      : '')
                  }
                  to={`/application/${applicationId}/staging`}
                >
                  Staging
                </Link>
              </li>
            )}
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/application\/[a-fA-F0-9-]+\/backup-restore\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/application/${applicationId}/backup-restore`}
              >
                Backup & Restore
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/application\/[a-fA-F0-9-]+\/optimizations\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/application/${applicationId}/optimizations`}
              >
                Optimizations
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (/^\/application\/[a-fA-F0-9-]+\/security\/.*/.test(location.pathname) &&
                    'active')
                }
                to={`/application/${applicationId}/security`}
              >
                Security
              </Link>
            </li>
            <li className='nav-item'>
              <Link
                className={
                  `nav-link text-active-primary me-6 ` +
                  (location.pathname === '/application/' + applicationId + '/crons' ? 'active' : '')
                }
                to={`/application/${applicationId}/crons`}
              >
                Crons
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}

export {ApplicationHeader}
