import React, {useEffect, useState} from 'react'
import {useParams} from 'react-router-dom'
import {notifyError, notifySuccess} from '../../../../../../utils/NotifyUtils'
import {
  BackupStatus,
  Backup,
  Application,
  RestoreStatus,
  BackupLocationType,
  BackupType,
} from '../../../../applications-list/core/_models'
import {
  deleteApplicationBackup,
  getApplicationBackups,
  getApplicationById,
  restoreApplicationBackup,
} from '../../../../applications-list/core/_requests'
import ConfirmSuccessModal from '../../../../../../utils/ConfirmSuccessModal'
import ConfirmDeleteModal from '../../../../../../utils/ConfirmDeleteModal'
import TabHeader from '../../../../../../utils/TabHeader'
import TabBody from '../../../../../../utils/TabBody'

const Restore = () => {
  const {applicationId} = useParams()
  const [backups, setBackups] = useState<Backup[]>([])
  const [backupId, setBackupId] = useState<string>('')
  const [isPolling, setIsPolling] = useState(false)
  const [showRestoreConfirmation, setShowRestoreConfirmation] = useState(false)
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false)
  const [application, setApplication] = useState<Application>()

  useEffect(() => {
    getApplicationById(applicationId).then((resp) => {
      setApplication(resp)
    })
  }, [])

  useEffect(() => {
    getBackups()
    const pollInterval = setInterval(() => {
      if (isPolling) {
        getBackups()
      }
    }, 5000)
    return () => clearInterval(pollInterval)
  }, [isPolling])

  const getBackups = async () => {
    try {
      const backups = await getApplicationBackups(applicationId ?? '')
      setBackups(backups!)
      if (
        backups?.some(
          (backup) =>
            backup.status === BackupStatus.IN_PROGRESS ||
            backup.restore_status === RestoreStatus.IN_PROGRESS
        )
      ) {
        setIsPolling(true)
      }
    } catch (error) {
      console.error('Error fetching backups', error)
    }
  }

  const handleRestore = async (id: string) => {
    setBackupId(id)
    setShowRestoreConfirmation(true)
  }

  const handleConfirmRestore = async () => {
    try {
      const success = await restoreApplicationBackup(applicationId ?? '', backupId)
      if (success === true) {
        notifySuccess('Backup restoration initiated')
        getBackups()
      } else {
        notifyError('Something went wrong')
      }
    } catch (error: any) {
      notifyError('Restore failed: ' + error.message)
    }
  }

  const handleDelete = async (id: string) => {
    setBackupId(id)
    setShowDeleteConfirmation(true)
  }

  const handleConfirmDelete = async () => {
    try {
      const success = await deleteApplicationBackup(applicationId ?? '', backupId)
      if (success === true) {
        notifySuccess('Backup deletion succeeded')
        setTimeout(() => {
          window.location.reload()
        }, 3000)
      } else {
        notifyError('Something went wrong')
      }
    } catch (error) {
      notifyError('Something went wrong')
    }
  }

  return (
    <div className='container-fluid'>
      <TabHeader heading='Restore' />
      <TabBody>
        {backups?.some((backup) => backup.restore_status === RestoreStatus.IN_PROGRESS) ? (
          <div className='alert alert-warning'>
            Application restoration is in progress for backup named{' '}
            {backups.find((backup) => backup.restore_status === RestoreStatus.IN_PROGRESS)?.name}.
          </div>
        ) : backups?.some((backup) => backup.status === BackupStatus.IN_PROGRESS) ? (
          <div className='alert alert-warning'>
            Backup in progress. We'll list down all backups once the backup is completed.
          </div>
        ) : (
          <table className='table'>
            <thead>
              <tr>
                <th>
                  <h4>Name</h4>
                </th>
                <th>
                  <h4>Date</h4>
                </th>
                <th>
                  <h4>Type</h4>
                </th>
                <th>
                  <h4>Actions</h4>
                </th>
              </tr>
            </thead>
            <tbody>
              {backups.map((backup) => (
                <tr key={backup.id} className='backup-item' style={{verticalAlign: 'middle'}}>
                  <td>{backup.name}</td>{' '}
                  {/** TODO: remove datetime part and append suffix if available */}
                  <td>{String(new Date(backup.created_at))}</td>
                  <td>
                    {backup.type} -&nbsp;
                    {backup.location === BackupLocationType.LOCAL ? backup.location : ''}
                    {backup.storage_provider}
                  </td>
                  <td style={{display: 'flex'}}>
                    <button
                      onClick={() => handleRestore(backup.id ?? '')}
                      className='btn btn-light-success'
                      disabled={backup.status === BackupStatus.FAILED}
                    >
                      Restore
                    </button>

                    <div style={{marginRight: '12px'}}></div>
                    <button
                      onClick={() => handleDelete(backup.id ?? '')}
                      className='btn btn-light-danger'
                      disabled={backup.type === BackupType.AUTOMATIC}
                    >
                      Delete
                    </button>

                    <div style={{marginRight: '12px'}}></div>
                    <button
                      onClick={() => {
                        const url =
                          backup.location === BackupLocationType.LOCAL
                            ? `https://${
                                application?.custom_domain ?? application?.internal_domain
                              }/backup/${backup.fileName}.tar.gz`
                            : `${process.env['REACT_APP_API_URL']}/v1/application/${applicationId}/backup/download?backupId=${backup.id}&entity=FILES`

                        window.location.href = url
                      }}
                      className='btn btn-light-primary'
                      disabled={backup.type === BackupType.AUTOMATIC}
                    >
                      Download Files
                    </button>

                    <div style={{marginRight: '12px'}}></div>
                    <button
                      onClick={() => {
                        const url =
                          backup.location === BackupLocationType.LOCAL
                            ? `https://${
                                application?.custom_domain ?? application?.internal_domain
                              }/backup/${backup.fileName}.sql`
                            : `${process.env['REACT_APP_API_URL']}/v1/application/${applicationId}/backup/download?backupId=${backup.id}&entity=DB`

                        window.location.href = url
                      }}
                      className='btn btn-light-info'
                      disabled={backup.type === BackupType.AUTOMATIC}
                    >
                      Download DB
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {showRestoreConfirmation && (
          <ConfirmSuccessModal
            setIsOpen={setShowRestoreConfirmation}
            title='Are you sure?'
            btnTitle='Restore'
            content='The current state of your application will be lost and this backup will be restored.'
            successFunction={handleConfirmRestore}
          />
        )}

        {showDeleteConfirmation && (
          <ConfirmDeleteModal
            setIsOpen={setShowDeleteConfirmation}
            title='Are you sure?'
            content='This backup will be permanently deleted.'
            deleteFunction={handleConfirmDelete}
          />
        )}
        <div className='mt-8'></div>
      </TabBody>
    </div>
  )
}

export default Restore
